<template>
  <download-excel default-value="Não informado" :fields="json_fields" :name="file_name" :data="data">
    <v-btn :height="height || '35'" :width="width || '100%'" color="grey" outlined>
      <v-icon class="pr-1">{{ icon }}</v-icon>
      {{ button_name }}
    </v-btn>
  </download-excel>
</template>

<script>
export default {
  props: {
    json_fields: {
      required: true,
      type: Object,
    },
    data: {
      required: true,
      type: Array,
    },
    file_name: {
      required: true,
      type: String,
    },
    button_name: {
      required: true,
      type: String,
    },
    icon: {
      required: false,
      type: String,
    },
    width: {
      required: false
    },
    height: {
      required: false
    }
  },
};
</script>

<style></style>
