<template>
  <v-row class="pa-5 align-center">
    <overlay :loading="loading" />
    <v-col class="" cols="12">
      <v-btn class="pl-0" text @click="$router.go(-1)">
        <v-icon class="pr-1">mdi-chevron-left</v-icon>
        Voltar
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      lg="8"
      md="8"
      class="text-h6 d-flex justify-start text-center"
    >
      <v-row class="align-center">
        <v-col cols="12" xm="2" sm="2" md="1">
          <v-avatar class="mr-2" color="primary" size="50">
            {{ `${patientData.name ? patientData.name.slice(0, 1) : "D"}` }}
          </v-avatar>
        </v-col>
        <v-col cols="12" sm="4" class="text-start">
          {{ patientData.name || "Desconhecido" }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>
    <v-col cols="12" class="text-h6 black--text pb-0">
      Atendimento nº {{ attendanceData.id }}
    </v-col>
    <v-col cols="12" class="subtitle-2 grey--text pt-0">
      Detalhes do atendimento
    </v-col>
    <v-col v-if="!$route.params.attendance_id" cols="pb-0">
      Escolha um alerta para abrir atendimento
      <span class="red--text">*</span>
    </v-col>
    <v-col cols="12">
      <v-form lazy-validation v-model="validForm" ref="createAttendanceForm">
        <v-row>
          <v-col v-if="alerts.length" cols="12" class="pt-0">
            <v-card width="100%" elevation="0" outlined>
              <v-card-text class="d-flex no-wrap overflow-x-auto">
                <v-slide-group>
                  <div
                    class="pa-1"
                    v-for="(item, index) in alerts"
                    :key="index"
                  >
                    <v-slide-item v-slot="{ active, toggle }">
                      <v-card
                        class="align-self-end"
                        outlined
                        width="250"
                        height="100%"
                        @click="
                          () => {
                            toggle();
                            attendanceData.alert_id = item.id;
                          }
                        "
                        :class="{ selected: active }"
                      >
                        <v-toolbar :color="item.color" dark>
                          <v-spacer></v-spacer>
                          <v-toolbar-title class="subtitle-2">{{
                            item.title
                          }}</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                          <v-row>
                            <v-col
                              cols="12"
                              class="subtitle-1 black--text font-weight-bold text-center pb-0"
                            >
                              {{ item.patient.name }}
                            </v-col>
                            <v-col
                              cols="12"
                              class="text-h5 font-weight-bold text-center pb-0"
                              :style="'color: ' + item.color"
                              >{{
                                item.title === "Nenhuma notificação"
                                  ? "Normal"
                                  : item.value
                              }}
                            </v-col>
                            <v-col cols="12" class="text-center pt-0">
                              {{
                                item.title === "Nenhuma notificação"
                                  ? ""
                                  : item.title.split(" ")[0]
                              }}
                            </v-col>
                            <v-col cols="12" class="pt-0">
                              <v-divider></v-divider>
                            </v-col>
                            <v-col
                              cols="12"
                              class="subtitle-2 text-center pt-0"
                            >
                              {{ formatLastUpdate(item.created_at) }}
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-slide-item>
                  </div>
                </v-slide-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="!alerts.length" cols="12" class="pt-0 grey--text">
            Nenhum alerta encontrado
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-row>
              <v-col cols="12" class="pb-0">
                Descrição do atendimento
                <span class="red--text">*</span>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-textarea
                  :disabled="$route.name == 'AttendancePatientShow'"
                  v-model="attendanceData.subject_matter"
                  outlined
                  dense
                  required
                  :rules="[(v) => !!v || 'Descrição é obrigatório']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="$route.params.attendance_id"
            cols="6"
            class="pt-0 caption black--text font-weight-normal"
          >
            Realizado no dia {{ attendanceData.created_at }} por
            <span class="black--text font-weight-black">
              {{ attendanceData.responsible || "..." }}</span
            >
          </v-col>
          <v-col
            class="pt-0 grey--text caption text-end"
            v-if="attendanceData.created_at != attendanceData.date"
            cols="6"
          >
            (Editado dia {{ attendanceData.date }} )
          </v-col>
          <v-col cols="12">
            <v-select
              dense
              outlined
              label="Status"
              placeholder="selecione"
              v-model="attendanceData.status"
              :items="status"
              required
              :rules="[(v) => !!v || 'Status é obrigatório']"
            ></v-select>
          </v-col>
          <v-col v-if="$route.params.attendance_id" cols="12">
            <excel
              :data="[attendance]"
              :width="$vuetify.breakpoint.mobile ? '100%' : 150"
              icon="mdi-download"
              button_name="Exportar"
              :file_name="`${patientData.name}_atendimento.xls`"
              :json_fields="json_fields_attendance"
            />
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-end"
            v-if="
              !$route.params.attendance_id ||
              $route.fullPath.split('/').includes('edit')
            "
          >
            <v-btn
              @click="
                $route.name == 'AttendancePatientEdit'
                  ? editAttendance()
                  : createAttendance()
              "
              :disabled="!validForm || !alerts.length"
              class="primary"
              :width="$vuetify.breakpoint.mobile ? '100%' : 150"
            >
              {{ $route.name == "AttendancePatientEdit" ? "Salvar" : "Criar" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Excel from "../../../../components/Excel/Excel.vue";
import Overlay from "../../../../components/Overlay/Overlay.vue";

export default {
  components: {
    Overlay,
    Excel,
  },
  data: () => ({
    validForm: true,
    model: null,
    loading: false,
    patient: {},
    attendance: {},
    alerts: [],
    json_fields_attendance: {
      Id: "id",
      Data: {
        field: "date",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Responsável: {
        field: "responsible",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Descrição: {
        field: "subject_matter",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
    },
    status: [
      { text: "Agendado",                     value: "scheduled" },
      { text: "Em análise",                   value: "under_review" },
      { text: "Concluído",                    value: "concluded" },
      { text: "Encerrado",                    value: "closed" },
      { text: "Encaminhar equipe",            value: "forward" },
      { text: "Entrar em contato familiar",   value: "get_family" },
    ],
  }),
  mounted() {
    if (this.$route.params.patient_id) {
      if (!(this.$route.name == "AttendancePatientShow")) this.showAlerts();
      this.patient = this.findPatient(this.$route.params.patient_id);
    }

    if (this.$route.params.attendance_id) {
      if (!this.$route.params.data) return this.showAttendance();
      else {
        const alert = this.$route.params.data.alert;

        this.attendance = this.$route.params.data;
        this.alerts = [
          {
            id: alert.id,
            user_id: this.patient.id,
            title: this.onChangeAlertTemplate(alert).title,
            color: this.onChangeAlertTemplate(alert).color,
            value: this.onChangeAlertTemplate(alert).value,
            risk: this.onChangeAlertTemplate(alert).risk,
            patient: {
              name: this.patient.name,
              age: this.patient.age,
              phone: this.patient.phone,
            },
            created_at: alert.created_at,
          },
        ];
      }
    }
  },
  methods: {
    ...mapActions("patients", ["getPatients"]),
    async showAlerts() {
      try {
        const response = await this.$axios(
          "alerts/" + this.$route.params.patient_id
        );

        if (response.data) {
          response.data.map((alert) => {
            const uniqueAlerts = this.alerts.some((el) => el.id === alert.id);

            if (!uniqueAlerts) {
              this.alerts = [
                ...this.alerts,
                {
                  id: alert.id,
                  user_id: this.patient.id,
                  title: this.onChangeAlertTemplate(alert).title,
                  color: this.onChangeAlertTemplate(alert).color,
                  value: this.onChangeAlertTemplate(alert).value,
                  risk: this.onChangeAlertTemplate(alert).risk,
                  patient: {
                    name: this.patient.name,
                    age: this.patient.age,
                    phone: this.patient.phone,
                  },
                  created_at: alert.created_at,
                },
              ];
            }
          });
        }
      } catch (error) {
        console.log("Alerts error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar os alertas." + error,
        });
      }
    },
    async showAttendance() {
      try {
        this.loading = true;

        const response = await this.$axios(
          "/attendances/" + this.$route.params.attendance_id
        );
        if (response.data) {
          console.log(response.data);

          this.attendanceData = {
            date: this.formatDateTime(response.data.updated_at),
            id: response.data.id,
            status: {
              text: this.scheduleStatusFormatting(response.data.status),
              value: response.data.status,
            },
            alert_id: response.data.alert_id,
            responsible: response.data.responsible.name,
            urgency: response.data.alert.risk == "high" ? "Alta" : "Baixa",
            subject_matter: response.data.subject_matter,
            sensor: response.data.sensor,
            service: response.data.service,
            annotation: response.data.annotation,
            created_at: this.formatDateTime(response.data.created_at),
          };

          if (response.data.alert) {
            const alert = response.data.alert;

            this.alerts = [
              {
                id: alert.id,
                user_id: this.patient.id,
                title: this.onChangeAlertTemplate(alert).title,
                color: this.onChangeAlertTemplate(alert).color,
                value: this.onChangeAlertTemplate(alert).value,
                risk: this.onChangeAlertTemplate(alert).risk,
                patient: {
                  name: this.patient.name,
                  age: this.patient.age,
                  phone: this.patient.phone,
                },
                created_at: alert.created_at,
              },
            ];
          }
        }
        this.loading = false;
      } catch (error) {
        console.log("Get attendance error: ", error);
        this.loading = false;
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar os atendimentos." + error,
        });
      }
    },
    async createAttendance() {
      if (!this.attendanceData.alert_id) {
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Por favor, selecione um alerta para iniciar o atendimento.",
        });

        return;
      }

      if (!this.$refs.createAttendanceForm.validate()) {
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Por favor, preencha os campos obrigatórios.",
        });

        return;
      }

      try {
        await this.$axios.post("attendances", {
          attendance: Object.assign(
            { responsible_id: this.currentUser.id },
            this.attendanceData
          ),
        });

        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Atendimento criado com sucesso.",
        });

        this.$store.dispatch("alerts/deleteAlert", {
          alert: {
            id: this.attendanceData.alert_id,
          },
        });

        this.getPatients();

        this.$router.push({
          name: "Patient",
          params: {
            id: this.patient.id,
            type: "attendance",
          },
        });
      } catch (error) {
        console.log("Create attendance error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar criar o atendimento.",
        });
      }
    },
    async editAttendance() {
      if (!this.attendanceData.alert_id) {
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Por favor, selecione um alerta para editar o atendimento.",
        });

        return;
      }

      if (!this.$refs.createAttendanceForm.validate()) {
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Por favor, preencha os campos obrigatórios.",
        });

        return;
      }

      try {
        await this.$axios.put(
          "attendances/" + this.$route.params.attendance_id,
          {
            attendance: Object.assign(
              { responsible_id: this.currentUser.id },
              this.attendanceData
            ),
          }
        );
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Atendimento editado com sucesso.",
        });

        this.$router.push({
          name: "Patient",
          params: {
            id: this.patient.id,
            type: "attendance",
          },
        });
      } catch (error) {
        console.log("Edit attendance error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar editar o atendimento.",
        });
      }
    },
    formatDateTime(dataTime) {
      if (!dataTime) return "Não informado";

      const day = dataTime.slice(8, 10);
      const month = dataTime.slice(5, 7);
      const year = dataTime.slice(0, 4);
      const time = dataTime.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    scheduleStatusFormatting(status) {
      switch (status) {
        case "scheduled":
          return {
            title: "Agendado",
            color: "#096FA9",
          };
        case "under_review":
          return {
            title: "Em análise",
            color: "#ED7F30",
          };
        case "concluded":
          return {
            title: "Concluído",
            color: "#09A90F",
          };
        case "closed":
          return {
            title: "Encerrado",
            color: "#CF4444",
          };
        case "get_family":
          return {
            title: "Entrar em contato familiar",
            color: "#CF4444",
            value: status,
          };
        default:
          return {
            title: "Agendado",
            color: "#096FA9",
          };
      }
    },
    onChangeAlertTemplate(alert) {
      if (!alert.alertable) {
        switch (alert.status) {
          case "falldown_exam":
            return {
              title: "Alerta de queda",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Alerta de queda",
              risk: alert ? alert.risk : "none",
            };
          case "sos_exam":
            return {
              title: "SoS Pressionado",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "SoS Pressionado",
              risk: alert ? alert.risk : "none",
            };
          case "fence_out_exam":
            return {
              title: "Limite de cerca quebrada",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Fora de Cerca",
              risk: alert ? alert.risk : "none",
            };
          case "fence_in_exam":
            return {
              title: "Area limite ultrapassada",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Area Limite",
              risk: alert ? alert.risk : "none",
            };
          case "low_battery_exam":
            return {
              title: "Dispositivo descarregando",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Dispositivo",
              risk: alert ? alert.risk : "none",
            };
          case "wifi_in_exam":
            return {
              title: "Dentro da cerca de Wifi",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "WiFi",
              risk: alert ? alert.risk : "none",
            };
          case "wifi_out_exam":
            return {
              title: "Fora da cerca de Wifi",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "WiFi",
              risk: alert ? alert.risk : "none",
            };
          case "device_offline_exam":
            return {
              title: "Dispositivo offline",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Dispositivo",
              risk: alert ? alert.risk : "none",
            };
          case "sedentary_exam":
            return {
              title: "Tempo Ocioso",
              color: "#CF4444",
              colorC: "#D6691A",
              value: "Sedentarismo",
              risk: alert ? alert.risk : "none",
            };
        }
      }
      const types = {
        temperature_dropped: {
          title: "Temperatura reduzida",
          color: "#ED7F30",
          value: alert && alert.alertable && alert.alertable.real_temperature + "°C",
          risk: alert ? alert.risk : "none",
        },
        temperature_rose: {
          title: "Temperatura elevada",
          color: "#CF4444",
          value: alert && alert.alertable && alert.alertable.real_temperature + "°C",
          risk: alert ? alert.risk : "none",
        },
        beats_dropped: {
          title: "Batimentos reduzidos",
          color: "#ED7F30",
          value: alert && alert.alertable && alert.alertable.bpm,
          risk: alert ? alert.risk : "none",
        },
        beats_rose: {
          title: "Batimentos elevados",
          color: "#CF4444",
          value: alert && alert.alertable && alert.alertable.bpm,
          risk: alert ? alert.risk : "none",
        },
        dbp_dropped: {
          title: "DBP reduzido",
          color: "#ED7F30",
          value: alert && alert.alertable && alert.alertable.dbp,
          risk: alert ? alert.risk : "none",
        },
        dbp_rose: {
          title: "DBP elevado",
          color: "#CF4444",
          value: alert && alert.alertable && alert.alertable.dbp,
          risk: alert ? alert.risk : "none",
        },
        sbp_dropped: {
          title: "SBP reduzido",
          color: "#ED7F30",
          value: alert && alert.alertable && alert.alertable.sbp,
          risk: alert ? alert.risk : "none",
        },
        sbp_rose: {
          title: "SBP elevado",
          color: "#CF4444",
          value: alert && alert.alertable && alert.alertable.dbp,
          risk: alert ? alert.risk : "none",
        },
        blood_oxygen_dropped: {
          title: "Oxigenação reduzida",
          color: "#CF4444",
          value: alert && alert.alertable && alert.alertable.blood_oxygen,
          risk: alert ? alert.risk : "none",
        },
        blood_oxygen_rose: {
          title: "Oxigenação elevada",
          color: "#ED7F30",
          value: alert && alert.alertable && alert.alertable.blood_oxygen,
          risk: alert ? alert.risk : "none",
        },
        total_sleep_dropped: {
          title: "Horas de sono reduzida",
          color: "#ED7F30",
          value:
            ((alert && alert.alertable && alert.alertable.deep_sleep_total  || 0) +
              alert && alert.alertable && alert.alertable.light_sleep_total || 0) /
            60,
          risk: alert.alert ? alert.risk : "none",
        },
        total_sleep_rose: {
          title: "Horas de sono elevada",
          color: "#CF4444",
          value:
            ((alert && alert.alertable && alert.alertable.deep_sleep_total  || 0) +
              alert && alert.alertable && alert.alertable.light_sleep_total || 0) /
            60,
          risk: alert.alert ? alert.risk : "none",
        },
      };

      return (
        types[alert ? alert.status : "nothing"] || {
          title: "Nenhuma notificação",
          color: "#256DF4",
          risk: "none",
        }
      );
    },
    formatLastUpdate(date) {
      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
    ...mapGetters({
      findPatient: "patients/findPatient",
      findAlert: "alerts/findAlert",
    }),
    patientData: {
      get() {
        return this.patient || {};
      },
      set(patient) {
        return (this.patient = patient);
      },
    },
    attendanceData: {
      get() {
        return this.attendance || {};
      },
      set(attendance) {
        return (this.attendance = attendance);
      },
    },
  },
  watch: {},
};
</script>

<style scoped>
.selected {
  background: #dddddd;
}
</style>
